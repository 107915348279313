
import Translations from "./Translations.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueQrious from "vue-qrious";
import { translations } from "@/mixins";


@Component({
  components: {
    Translations,
    VueQrious,
  },
  mixins: [translations],
})
export default class QueueBusterQrCode extends Vue {
  @Prop() data!: any;
  @Prop() showTable!: boolean;
  @Prop() tableName!: string;
  @Prop() coupon?: string;
  @Prop() selectedTable?: number;
  
  translate!: Function;

  mounted() {
    console.log("check coupon", this.coupon);
  }

  private qrCodeValue() {

    if (this.data.branch) {
      let value = `https://${this.data['web-uri']}/queuebuster?`;

      if (this.data.qr_type == 'eat-in' || this.data.qr_type == 'to-go') {
        value = `${value}location=${this.data.branch}`
      }

      value = `${value}&order-type=${this.data.qr_type}` // return this if not equal to deliver

      if (this.showTable && this.tableName) {
        value = `${value}&table=${encodeURIComponent(this.tableName)}`
      }

      if (this.coupon) {
        value += `&coupon=${this.coupon}`
      }

      console.log('qrCodeValue: ', value);
      return value
    } else {
        let value = `https://${this.data['web-uri']}`;

        console.log('qrCodeValue: ', value);
        return value
    }
    
  }
}
